<template>
    <div class="modal fade" id="payment-create-modal-id" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header  ms-modal-header-radius-0">
                    <h4 class="modal-title text-primary" v-if="editMode">Update Payemnt</h4>
                    <h4 class="modal-title text-primary" v-else>Add Payemnt</h4>
                    <button type="button" id="close-modal" class="btn btn-danger close" data-bs-dismiss="modal"
                            aria-hidden="true">x
                    </button>
                </div>
                <div class="modal-body p-0 text-left">
                    <div class="col-xl-12 col-md-12">
                        <div class="ms-panel ms-panel-bshadow-none">
                            <div class="ms-panel-body p-4">
                                <form class="needs-validation" @submit.prevent="editMode ? update() : store()">
                                    <div class="form-row row">
                                        <div class="col-md-6 mb-3">
                                            <label for="method">Method</label>
                                            <div class="input-group">
                                                <Multiselect required id="method" track-by="id" label="name"
                                                             :multiple="false"
                                                             :taggable="true" v-model="form.method"
                                                             :options="methods.map(type => type.name)"
                                                             :custom-label="opt => methods.find(x => x.name === opt).name"
                                                />
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-3">
                                            <label for="due_amount">Due Amount</label>
                                            <div class="input-group">
                                                <input readonly v-model="form.max_amount" type="number"
                                                       class="form-control" id="due_amount" placeholder="Due Amount">
                                            </div>
                                        </div>
                                        <div class="col-md-6 mb-3">
                                            <label class="d-block">Date</label>
                                            <DatePicker class="w-100" title="Payment Date" v_model="paid_on"/>
                                        </div>
                                        <div class="col-md-6 mb-3">
                                            <label for="pay_amount">Pay Amount</label>
                                            <div class="input-group">
                                                <input step="any" :max="form.max_amount" v-model="form.amount"
                                                       type="number"
                                                       class="form-control" id="pay_amount" placeholder="Amount"
                                                       required>
                                            </div>
                                        </div>
                                    </div>
                                    <button class="btn btn-primary mt-4 d-inline w-20">
                                        {{ editMode ? 'Update' : 'Save' }}
                                    </button>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a href="javascript:void(0)" class="d-none" id="btn-payment-create-modal" data-bs-toggle="modal"
           data-bs-target="#payment-create-modal-id"></a>
    </div>
</template>

<script>
import DatePicker          from "@/components/picker/DatePicker";
import Multiselect         from "vue-multiselect";
import ApiService          from "@/services/api.service";
import NotificationService from "@/services/notification.service";
import moment              from "moment";

export default {
    name      : "PaymentCreateModal",
    components: {Multiselect, DatePicker},
    props     : ['from'],
    data      : () => ({
        editMode: false,
        form    : {
            transaction_id: '',
            method        : 'cash',
            amount        : 0,
            max_amount    : 0,
            paid_on       : moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
        },
        methods : [
            {id: 1, name: "cash"},
            {id: 2, name: "visa"},
            {id: 3, name: "cheque"},
            {id: 4, name: "bank_transfer"},
            {id: 5, name: "other"},
        ]
    }),
    methods   : {
        openPaymentModal() {
            document.querySelectorAll("#btn-payment-create-modal")[0].click();
        },
        store() {
            this.form.is_due_payment = true;
            ApiService.post('/transaction-payments', this.form).then((res) => {
                NotificationService.success(res.data.message);
                this.formReset();
                this.$parent.getList();
                // this.$parent.print(res.data.data.transaction_id);
                document.querySelectorAll("#close-modal")[0].click();
            }).catch(error => {
                NotificationService.error(error.response.data.message);
            })
        },
        edit(id) {
            this.editMode = true;
            ApiService.get(`/transaction-payments/${id}`).then((res) => {
                this.form = res.data.data;
            }).catch(error => {
                NotificationService.error(error.response.data.message);
            })
        },
        update() {
            ApiService.update(`/transaction-payments/${this.form.id}`, this.form).then((res) => {
                NotificationService.success(res.data.message);
                this.formReset();
                this.$parent.getList();
                // this.$parent.print(res.data.data.transaction_id);
                document.querySelectorAll("#close-modal")[0].click();
            }).catch(error => {
                NotificationService.error(error.response.data.message);
            })
        },
        formReset() {
            this.form = {
                transaction_id: '',
                method        : 'cash',
                amount        : 0,
                max_amount    : 0,
                paid_on       : moment(new Date()).format("YYYY-MM-DD HH:mm:ss"),
            }
        }
    }
}
</script>

<style scoped>

</style>
