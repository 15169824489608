<template>
    <div class="modal fade" id="payment-list-modal-id" tabindex="-1" role="dialog" aria-hidden="true">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="modal-header  ms-modal-header-radius-0">
                    <h4 class="modal-title text-primary">List Payment</h4>
                    <button type="button" id="close-modal" class="btn btn-danger close" data-bs-dismiss="modal"
                            aria-hidden="true">x
                    </button>
                </div>
                <div class="modal-body p-0 text-left">
                    <div class="col-xl-12 col-md-12">
                        <div class="ms-panel ms-panel-bshadow-none">
                            <div class="ms-panel-body">
                                <div class="table-responsive">
                                    <table class="table table-striped table-hover thead-primary w-100">
                                        <thead>
                                        <tr>
                                            <th class="ps-3" scope="col">#</th>
                                            <th scope="col">Date</th>
                                            <th scope="col">Amount</th>
                                            <th scope="col">Payment Method</th>
                                            <th scope="col">Action</th>
                                        </tr>
                                        </thead>
                                        <tbody>
                                        <template v-if="$parent.transaction_payments.length">
                                            <tr v-for="(payment, index) in $parent.transaction_payments">
                                                <th scope="row" class="ps-3">{{ ++index }}</th>
                                                <td>{{ payment.paid_on |dateFormat }}</td>
                                                <td>{{ payment.amount | numberFormat }}</td>
                                                <td style="text-transform: capitalize">{{ payment.method }}</td>
                                                <td>
                                                    <div class="d-flex gap-2">
                                                        <update-action type="modal"
                                                                       v-if="$root.$can('invoice', 'invoice-update')"
                                                                       :id="payment.id"/>

                                                        <delete-action class="w-auto" v-if="$root.$can('invoice', 'invoice-delete')"
                                                                       :id="payment.id"/>
                                                    </div>
                                                </td>
                                            </tr>
                                            <tr>
                                                <th class="text-center font-weight-bolder" colspan="2">
                                                    Total Payment
                                                </th>
                                                <th class="text-center font-weight-bolder" colspan="3">
                                                    {{ totalPayment | numberFormat }}
                                                </th>
                                            </tr>
                                        </template>
                                        <template v-if="!$parent.transaction_payments.length">
                                            <tr>
                                                <th class="text-center font-weight-bolder" colspan="100%">No data
                                                    found
                                                </th>
                                            </tr>
                                        </template>
                                        </tbody>
                                    </table>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <a href="javascript:void(0)" class="d-none" id="btn-payment-list-modal" data-bs-toggle="modal"
           data-bs-target="#payment-list-modal-id"></a>
    </div>
</template>

<script>
import ApiService          from "../../../../../../services/api.service";
import NotificationService from "../../../../../../services/notification.service";

export default {
    name    : "PaymentListModal",
    data    : () => ({}),
    props   : ['from'],
    computed: {
        totalPayment() {
            let total_payment = 0;
            this.$parent.transaction_payments.map((payment, i) => {
                total_payment += parseFloat(payment.amount);
            });
            return total_payment;
        }
    },
    methods : {
        openPaymentModal() {
            document.querySelectorAll("#btn-payment-list-modal")[0].click();
        },
        edit(id) {
            document.querySelectorAll("#close-modal")[0].click();
            this.$parent.payment_create_modal = true;
            const component_created           = setInterval(() => {
                if (this.$parent.$refs['payment_create_modal']) {
                    this.$parent.$refs['payment_create_modal'].editMode = true;
                    ApiService.get(`/transaction-payments/${id}`).then((res) => {
                        this.$parent.$refs['payment_create_modal'].form = res.data.data;
                        // this.$parent.$refs['payment_create_modal'].form.max_amount = res.data.data.transaction.total_due;
                        this.$parent.$refs['payment_create_modal'].openPaymentModal();
                    }).catch(error => {
                        NotificationService.error(error.response.data.message);
                    })
                    clearInterval(component_created);
                }
            }, 100);
        },
        destroy(id) {
            Swal.fire({
                title             : 'Are you sure?',
                text              : "You won't be able to revert this!",
                icon              : 'warning',
                showCancelButton  : true,
                confirmButtonColor: '#3085d6',
                cancelButtonColor : '#d33',
                confirmButtonText : 'Yes, delete it!'
            }).then((result) => {
                if (result.isConfirmed) {
                    ApiService.delete(`/transaction-payments/${id}`,).then(res => {
                        NotificationService.success(res.data.message);
                        document.querySelectorAll("#close-modal")[0].click();
                        this.$parent.getList();
                    }).catch(error => {
                        NotificationService.error(error.response.data.message);
                    })
                }
            }).catch(error => {
                NotificationService.error(error.response.data.message);
            })
        },
    }
}
</script>

<style scoped>

</style>
